import React from "react";

import * as DateUtils from "@src/utils/date.utils";

const MangaDetails = ({manga}) => (
    <>
        <div className="bloc-title">
            <h3>Première publication :</h3>
            <span>{DateUtils.formatLitteralDate(manga.date_deb)}</span>
        </div>
        <div className="bloc-title">
            <h3>Dernière publication :</h3>
            <span>{manga.date_fin ? DateUtils.formatLitteralDate(manga.date_fin) : "En cours .."}</span>
        </div>
    </>
);

export default MangaDetails;