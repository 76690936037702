import {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

import {Navigation} from "@src/components";
import {login} from "@src/services/AuthService";
import {AuthContext} from "@src/context";

import ShowAndHidePassword from "./components/ShowAndHidePassword";

const Login = () => {
    // Constante permettant de naviguer entre plusieurs pages
    const navigate = useNavigate();
    // Permet de savoir si l'utilisateur est connecté
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    // Récupère l'erreur qui peut se produire lors de la connexion
    const [error, setError] = useState(false);
    // Username
    const usernameInputRef = useRef();
    // Password
    const passwordInputRef = useRef();

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/animes");
        }
    }, [navigate, isAuthenticated]);

    // Récéptionne l'envoi du formulaire
    const handleSubmit = async event => {
        event.preventDefault();

        setError(false);
        try {
            const username = usernameInputRef.current.value;
            const password = passwordInputRef.current.value;

            const response = await login({username, password});
            setIsAuthenticated(response);
            navigate("/animes");
        } catch ({ response }) {
            setError(true);
        }
    };

    return (
        <>
            <Navigation />
            <section id="login-form">
                {error &&
                    <div className="error-message">
                        <img src="/img/warning-logo.png" alt="Warning PNG"/>
                        <div>
                            <p>Un problème est survenu</p>
                            <span>Identifiant ou mot de passe incorrect</span>
                        </div>
                    </div>
                }
                <form onSubmit={handleSubmit}>
                    <h1>Se connecter</h1>
                    <div className="input-group">
                        <label htmlFor="username">Identifiant :</label>
                        <input type="text" name="username" id="username" ref={usernameInputRef}/>
                    </div>
                    <div className="input-group">
                        <label htmlFor="password">Mot de passe :</label>
                        <ShowAndHidePassword ref={passwordInputRef}/>
                    </div>
                    <div className="anima-logo">
                        <img src="/favicon/android-chrome-192x192.png" alt="AniMa Logo"/>
                        <span>AniMa</span>
                    </div>
                    <div className="input-group">
                        <button>Connexion</button>
                    </div>
                </form>
            </section>
        </>
    );
}

export default Login;