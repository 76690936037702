export class Note {
    private _id: number;
    private _libelle: string;

    constructor(id, libelle) {
        this._id = id;
        this._libelle = libelle;
    }

    getId(): number {
        return this._id;
    }

    setId(id: number): void {
        this._id = id;
    }

    getLibelle(): string {
        return this._libelle;
    }

    setLibelle(libelle: string): void {
        this._libelle = libelle;
    }
}