import {useEffect, useState} from "react";

import {useGetData} from "@src/hooks";

export const useGetMangas = (params) => {
    // La liste des mangas
    const [mangas, setMangas] = useState(null);
    // Le nombre de page
    const [pageCount, setPageCount] = useState(null);
    // La réponse de la requête API
    const {response} = useGetData("/api/v2/mangas", params);

    useEffect(() => {
        if (response) {
            setMangas(response.results);
            setPageCount((Math.ceil(response.meta.total_results / response.meta.results_per_page)));
        }
    }, [response]);

    return {mangas, pageCount};
}