import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

import {LoadingScreen, Navigation, Pagination} from "@src/components";
import {useGetGenres} from "./hooks";
import {GenresList, SortGenres} from "./components";

const AllGenre = () => {
    // Constante permettant de naviguer entre plusieurs pages
    const navigate = useNavigate();
    // La page courrante
    const [currentPage, setCurrentPage] = useState(1);

    // La liste des genres, le nombre de page associé, le status de la requête et le filtre en cours sur la liste
    // des genres (null = ALL)
    const {genres, pageCount, loading, alphabetFilter} = useGetGenres(new URLSearchParams([
        ['page', currentPage],
        ['per_page', 16]
    ]));

    // Mise à jour de la page sur laquelle on se trouve
    let handlePageClick = (page) => {
        setCurrentPage(page);
    }

    // Mise à jour du filtre sur la liste des genres
    let handleSortGenres = (letter) => {
        if (letter) {
            setCurrentPage(1);
            navigate(`/genres?startWith=${letter}`);
        } else {
            setCurrentPage(1);
            navigate(`/genres`);
        }
    }

    document.title = "Genres - AniMa";

    return (
        <>
            <Navigation/>
            <div id="container-genres">
                <SortGenres alphabetFilter={alphabetFilter} handleSortGenres={handleSortGenres}/>
                {!genres ? (
                    loading ? (
                        <LoadingScreen increase={true}/>
                    ) : (
                        <div className="no-result">
                            <h1>aucun genre en <b>{alphabetFilter}</b> !</h1>
                            <span>essayer avec une autre lettre</span>
                        </div>
                    )
                ) : (
                    <GenresList genres={genres}/>
                )}
                <Pagination
                    pageCount={pageCount}
                    handlePageClick={handlePageClick}
                    current={currentPage}
                />
            </div>
        </>
    );
};

export default AllGenre;