import {useEffect, useState} from "react";

import {useGetData} from "@src/hooks";

export const useGetManga = (id) => {
    // Le manga récupéré
    const [manga, setManga] = useState(null);
    // La réponse de la requête API
    const {response} = useGetData(`/api/v2/mangas/${id}`, {});

    useEffect(() => {
        if (response) {
            setManga(response.results);
        }
    }, [response]);

    return {manga};
}