import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";

import {SearchContext} from "@src/context";
import {handleBlur, handleFocus} from "./handlers";

const Searchbar = () => {
    // L'handler qui écoute l'action qui initie la recherche ainsi que la liste sur laquelle effectuer la recherche
    const {handleSearchClick, listObjects} = useContext(SearchContext)
    // Constante permettant de naviguer entre plusieurs pages
    const navigate = useNavigate();
    // La saisie de l'utilisateur dans la barre de recherche
    const [searchInput, setSearchInput] = useState(null);
    // La liste des animes affichés dans la searchbar
    const [displayList, setDisplayList] = useState(null);

    // Met à jour la liste des animes dans la searchbar en fonction de text recherché
    const handleChange = (event) => {
        const text = event.target.value.trim().toLowerCase();

        if (text) {
            const searchAnime = listObjects.filter(item => {
                return item.getLibelle().toLowerCase().includes(text);
            });
            setDisplayList(searchAnime.length > 0 ? searchAnime : null);
            setSearchInput(text);
        } else {
            setDisplayList(listObjects);
            setSearchInput(null);
        }
    };

    // Efface les résultats de recherche
    const clearSearch = () => {
        document.getElementById("search-input").value = "";
        setSearchInput(null);
        setDisplayList(null);
        handleSearchClick(null);
    }

    return (
        <section className="searchbar">
            <input
                id="search-input"
                type="text"
                placeholder="Recherchez un anime.."
                name="search"
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
            {searchInput && (
                <span className="clear-search-icon" onClick={clearSearch}
                ><FontAwesomeIcon icon={faCircleXmark} size="lg"/>
                </span>
            )}
            <span className="search-icon" onClick={() => handleSearchClick(displayList)}
            ><FontAwesomeIcon icon={faSearch} size="lg"/>
            </span>
            {searchInput && (
                <div id="search-results">
                    {!displayList ? (
                        <div className="no-result">
                            <h1>aucun anime trouvé !</h1>
                            <span>aucun anime ne correspond à votre recherche</span>
                        </div>
                    ) : (
                        displayList.map((anime, index) => (
                            <div key={index} className="search-item flex-box"
                                 onMouseDown={() => navigate(`/animes/${anime.getId()}`)}>
                                <div className="img-preview">
                                    <img src={`img/anime/${anime.getId()}/${anime.getImage()}`}
                                         alt="anime preview"
                                         loading="lazy"/>
                                </div>
                                <div className="about-anime">
                                    <h4>{anime.getLibelle()}</h4>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            )}
        </section>
    )
}

export default Searchbar;
