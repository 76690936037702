import React from 'react';
import {Link} from 'react-router-dom';

import {handleMouseEnter, handleMouseLeave} from "./handlers";

const Home = () => {

    document.title = "AniMa";

    return (
        <div className="container-fluid">
            <div
                className="split left"
                onMouseEnter={() => handleMouseEnter(true)}
                onMouseLeave={() => handleMouseLeave(true)}
            >
                <h1>Animes</h1>
                <Link to="/animes" preventScrollReset={true} className="btn">Visiter</Link>
            </div>
            <div
                className="split right"
                onMouseEnter={() => handleMouseEnter(false)}
                onMouseLeave={() => handleMouseLeave(false)}
            >
                <h1>Mangas</h1>
                <Link to="/mangas" preventScrollReset={true} className="btn">Visiter</Link>
            </div>
            <span id="credit">Artworks made by Parka (summersketch)</span>
        </div>
    );
}

export default Home;