// Ajustement de la taille du container sur lequel le click a été effectué
export const handleMangaClick = (index) => {
    const lstContainer = document.querySelectorAll("[id^='block-']");
    lstContainer.forEach(container => {
        const tinyContent = container.querySelector(".tiny-content");

        if (container.id !== `block-${index}`) {
            container.classList.remove('increase-container');
            tinyContent.classList.add("hide");
        } else {
            container.classList.add('increase-container');
            tinyContent.classList.remove("hide");
        }
    });
}