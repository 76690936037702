import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {Navigation, ResponseModal} from "@src/components";
import * as AnimeUtils from "@src/utils/animes.utils";
import {useGetNotes, useGetSuites} from "@src/hooks";
import {ModalContext, SearchContext} from "@src/context";
import * as AnimeStore from "@src/store/animes";

import {AddAnime, AnimesList, Preferences} from "./components";

const AllAnime = () => {
    // Constante permettant de naviguer entre plusieurs pages
    const navigate = useNavigate();
    // Permet d'afficher une modal sur la page
    const [showModal, setShowModal] = useState(false);
    // Permet de définir s'il s'agit d'une modal d'erreur ou de succès
    const [successModal, setSuccessModal] = useState(false);
    // Message à afficher dans la modale
    const [messageModal, setMessageModal] = useState("");
    // La liste des animes
    const [animesList, setAnimesList] = useState(null);
    // La liste des derniers animes
    const [lastAnimes, setLastAnimes] = useState(null);
    // La liste des notes
    const {notes} = useGetNotes();
    // La liste des suites
    const {suites} = useGetSuites();
    // La liste des animes récupéré depuis le store
    const fetchAnimes = useSelector((state) => state.animes);

    // Permet de faire appel aux actions du reducer
    const dispatch = useDispatch();

    useEffect(() => {
        setAnimesList(fetchAnimes);
        setLastAnimes(AnimeUtils.getLastAnimes(fetchAnimes));
    }, [fetchAnimes]);

    // La liste des animes issuent du résultat de la recherche via la serchbar
    const handleSearchAnime = (res) => {
        if (res) {
            setAnimesList(res);
        } else {
            setAnimesList(fetchAnimes);
        }
    }

    // Ajout un anime aux animes stockés en mémoire
    const updateListe = ({ anime }) => {
        dispatch(AnimeStore.addAnime(anime));
    }

    document.title = "Animes - AniMa";

    return (
        <ModalContext.Provider value={{ showModal, setShowModal, messageModal, setMessageModal, successModal, setSuccessModal }}>
            <ResponseModal />
            <div className="black-screen"></div>
            {lastAnimes && (
                <div className="bg-image"
                     style={{backgroundImage: `url(img/anime/${lastAnimes[0].getId()}/${lastAnimes[0].getImage()})`}}>
                </div>
            )}
            <div className="bg-text">
                <SearchContext.Provider value={{handleSearchClick: handleSearchAnime, listObjects: fetchAnimes}}>
                    <Navigation/>
                </SearchContext.Provider>
                <section id="container-animes" className="container flex-box">
                    <AnimesList animes={animesList}/>
                    <aside className="aside-content">
                        {lastAnimes && (
                            <div id="last-anime">
                                <p>Derniers Animes</p>
                                <div className="grid-container">
                                    {lastAnimes.map((anime, index) => (
                                        <div
                                            key={index}
                                            className="grid-item"
                                            style={{backgroundImage: `url(img/anime/${anime.getId()}/${anime.getImage()})`}}
                                            onClick={() => navigate(`/animes/${anime.getId()}`)}
                                        >
                                            <span>{anime.getLibelle()}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <Preferences
                            notes={notes}
                            suites={suites}
                        />
                        <AddAnime
                            notes={notes}
                            suites={suites}
                            updateList={updateListe}
                        />
                    </aside>
                </section>
            </div>
        </ModalContext.Provider>
    );
};

export default AllAnime;