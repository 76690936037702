import {createSelector} from "@reduxjs/toolkit";

export const selectFilterNote = createSelector(
    (state) => state.filtering.filterNote,
    (filterNote) => +filterNote,
);

export const selectFilterSuite = createSelector(
    (state) => state.filtering.filterSuite,
    (filterSuite) => +filterSuite,
);

export const selectSort = createSelector(
    (state) => state.filtering.sort,
    (sort) => sort,
);