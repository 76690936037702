import {Note, Suite} from ".";

export class Anime {
    private _id: number;
    private _libelle: string;
    private _synopsis: string;
    private _image: string;
    private _carte_image: string;
    private _nb_saison: number;
    private _dernier_visionnage: string;
    private _note: Note;
    private _suite: Suite;

    constructor();
    constructor(id: number, libelle: string, synopsis: string, image: string, carte_image: string, nb_saison: number,
                note: Note, suite: Suite, dernier_visionnage: string);
    constructor(id?: number, libelle?: string, synopsis?: string, image?: string, carte_image?: string, nb_saison?: number,
                note?: Note, suite?: Suite, dernier_visionnage?: string) {
        this._id = id;
        this._libelle = libelle;
        this._synopsis = synopsis;
        this._image = image;
        this._carte_image = carte_image;
        this._nb_saison = nb_saison;
        this._dernier_visionnage = dernier_visionnage;
        this._note = note;
        this._suite = suite;
    };

    transformToDTO(): { libelle: string, synopsis: string, image: string, carte_image: string, nb_saison: number,
        dernier_visionnage: string, note_id: number, suite_id: number } {
        return {
            libelle: this._libelle,
            synopsis: this._synopsis,
            image: this._image,
            carte_image: this._carte_image,
            nb_saison: this._nb_saison,
            dernier_visionnage: this._dernier_visionnage,
            // @ts-ignore
            note_id: this._note._id,
            // @ts-ignore
            suite_id: this._suite._id,
        };
    };

    applyPrivateAttribut(note: {_id: number, _libelle: string},
                         suite: {_id: number, _libelle: string}) {
        this._note = new Note(note._id, note._libelle);
        this._suite = new Suite(suite._id, suite._libelle);
    }

    getId(): number {
        return this._id;
    }

    setId(id: number): void {
        this._id = id;
    }

    getLibelle(): string {
        return this._libelle;
    }

    setLibelle(libelle: string): void {
        this._libelle = libelle;
    }

    getSynopsis(): string {
        return this._synopsis;
    }

    setSynopsis(synopsis: string): void {
        this._synopsis = synopsis;
    }

    getImage(): string {
        return this._image;
    }

    setImage(image: string): void {
        this._image = image;
    }

    getCarteImage(): string {
        return this._carte_image;
    }

    setCarteImage(carte_image: string): void {
        this._carte_image = carte_image;
    }

    getNbSaison(): number {
        return this._nb_saison;
    }

    setNbSaison(nb_saison: number): void {
        this._nb_saison = nb_saison;
    }

    getDernierVisionnage(): string {
        return this._dernier_visionnage;
    }

    setDernierVisionnage(dernier_visionnage: string): void {
        this._dernier_visionnage = dernier_visionnage;
    }

    getNote(): Note {
        return this._note;
    }

    setNote(note: Note): void {
        this._note = note;
    }

    getSuite(): Suite {
        return this._suite;
    }

    setSuite(suite: Suite): void {
        this._suite = suite;
    }
}