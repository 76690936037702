/**
 * Retourne la liste des 26 lettres de l'alphabet latin
 *
 * @returns {*[]}
 */
export function generateAlphabet() {
    const alphabets = [];

    // Récupère les 26 lettres de l'alphabet depuis leur code UTF-16
    for (let i = 65; i <= 90; i++) {
        alphabets.push(String.fromCharCode(i));
    }

    return alphabets;
}

/**
 * Récupère l'extension du fichier et le concatène à la chaîne de caractère
 *
 * @param file Le fichier d'où extraire l'extension
 * @returns {string} Le nom concaténé de l'image
 */
export function getFileName(file) {
    const extension = file.type;
    return "image." + extension.substring(6, extension.length);
}

/**
 * Permet de valider un formulaire représentatif de la structure d'un anime.
 *
 * @param form Le formulaire contenant certains champs composant un anime
 * @returns {Promise<unknown>} Retourne la résolution d'une promise ou son rejet en fonction de la validité du formulaire
 */
export function isValidAnimeForm({form}) {
    let isValid = true;

    const fieldLibelle = form['libelle'];
    const fieldSynopsis = form['synopsis']
    const fieldImage = form['image'];
    const fieldCarteImage = form['carte_image'];
    const fieldSaison = form['nb_saison'];
    const fieldNote = form['note'];
    const fieldSuite = form['suite'];
    const fieldDateVisionnage = form['date_visionnage'];

    if (!isValidField(fieldLibelle, document.getElementById('boxLibelle'), false)) {
        isValid = false;
    }
    if (!isValidField(fieldSynopsis, document.getElementById('boxSynopsis'), true)) {
        isValid = false;
    }
    if (!isValidField(fieldImage, document.getElementById('boxImage'), false)) {
        isValid = false;
    }
    if (!isValidField(fieldCarteImage, document.getElementById('boxCarteImage'), true)) {
        isValid = false;
    }
    if (!isValidField(fieldSaison, document.getElementById('boxNbSaison'), false)) {
        isValid = false;
    }
    if (!isValidField(fieldNote, document.getElementById('boxNote'), false)) {
        isValid = false;
    }
    if (!isValidField(fieldSuite, document.getElementById('boxSuite'), false)) {
        isValid = false;
    }
    if (!isValidField(fieldDateVisionnage, document.getElementById('boxDateVisionnage'), false)) {
        isValid = false;
    }

    return new Promise((resolve, reject) => {
        if (isValid) {
            resolve();
        } else {
            reject('Veuillez renseigner tous les champs du formulaire !');
        }
    });
}

/**
 * Permet de vérifier si le champ est renseigné
 *
 * @param element Le champ à vérifier
 * @param label Le label à mettre en évidence
 * @param isOptional Définit si l'élément doit être requis
 * @returns {boolean} Vrai si le champ est valide
 */
function isValidField(element, label, isOptional) {
    if (element) {
        label.classList.remove('highlight-error');
        if (!isOptional && !element.value) {
            label.classList.add('highlight-error');
            return false
        }
    }

    return true;
}