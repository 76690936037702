import axios from "axios";

import * as SecurityUtils from "@src/utils/security.utils";
import {addItem, getItem, removeItem} from "./LocalStorageService";

// Permet de savoir si l'utilisateur est connecté avec un token toujours valide
export function hasAuthenticated() {
    const token = getItem('api-token');
    const result = token ? SecurityUtils.tokenIsValid(token) : false;

    if (!result) {
        removeItem('api-token');
    }

    return result;
}

// Appel à la route de login pour identifer un utilisateur
export function login(credentials) {
    return axios
        .post('/api/v2/users/login', credentials)
        .then(response => response.data)
        .then(token => {
            addItem('api-token', token);

            return true;
        });
}

// Déconnecte l'utilisateur courant
export function logout() {
    removeItem('api-token');
}