import axios from "axios";

/**
 * Filtre une liste d'anime passée en paramètre en fonction du filtre renseigné
 *
 * @param animes La liste des animes
 * @param sort Le filtre renseigné
 * @returns {*[]} La liste filtrée
 */
function sortAnimes(animes, sort) {
    const regexSort = /^(?<sortBy>[a-zA-Z_]*):(?<order>[a-zA-Z]*)$/;
    let sortObject = null;

    if (sort != null && regexSort.test(sort)) {
        const res = sort.match(regexSort).groups;
        sortObject = {
            sort_by: res.sortBy,
            order: res.order
        }
    }

    if (sortObject) {
        // Création d'une copie triée du tableau d'animes
        let sortedAnimes = [...animes];

        switch (sortObject.sort_by) {
            case "libelle":
                sortedAnimes.sort(function(a, b) {
                    if (a.getLibelle() < b.getLibelle()) return -1;
                    if (a.getLibelle() > b.getLibelle()) return 1;
                    return 0;
                });
                break;
            case "nb_saison":
                sortedAnimes.sort((a, b) => b.getNbSaison() - a.getNbSaison());
                break;
            case "note_id":
                sortedAnimes.sort((a, b) => b.getNote().getId() - a.getNote().getId());
                break;
            case "suite_id":
                sortedAnimes.sort((a, b) => a.getSuite().getId() - b.getSuite().getId());
                break;
            case "dernier_visionnage":
                sortedAnimes.sort(function(a, b) {
                    if (a.getDernierVisionnage() < b.getDernierVisionnage()) return 1;
                    if (a.getDernierVisionnage() > b.getDernierVisionnage()) return -1;
                    return 0;
                });
                break;
            default:
                break;
        }
        return sortedAnimes;
    } else {
        // Si aucun tri n'est effectif
        return [...animes];
    }
}

/**
 * Permet de récupérer les 6 derniers animes en fonction de leur date de visionnage
 *
 * @param animes La liste des animes
 * @returns {*[]} La liste des 6 derniers animes
 */
export function getLastAnimes(animes) {
    // Création d'une copie triée du tableau d'animes
    let sortedAnimes = [...animes];

    sortedAnimes.sort(function(a, b) {
        if (a.getDernierVisionnage() < b.getDernierVisionnage()) return 1;
        if (a.getDernierVisionnage() > b.getDernierVisionnage()) return -1;
        return 0;
    });

    return sortedAnimes.slice(0, 6);
}

/**
 * Tri une liste d'anime passée en paramètre en fonction de tri courant et de la pagination
 *
 * @param animes La liste des animes
 * @param currentPage La page courante
 * @param perPage Le nombre d'anime sur une page
 * @param options Les options de tri
 * @returns {{pageCount: number, slice: *[]}} La liste triée
 */
export function getDisplayList(animes, currentPage, perPage, options = null) {
    if (options) {
        if (options.filterNote) {
            animes = animes.filter(anime => anime.getNote().getId() === options.filterNote);
            currentPage = 1;
        }
        if (options.filterSuite) {
            animes = animes.filter(anime => anime.getSuite().getId() === options.filterSuite);
            currentPage = 1;
        }
        if (options.sort) {
            animes = sortAnimes(animes, options.sort);
        }
    }

    // Le nombre de pages
    const pageCount = Math.ceil(animes.length / 20);

    // Permet de définir la taille du slice à produire
    const offset = (currentPage - 1) * [perPage];

    animes = animes.slice(offset, (offset + perPage));
    const slice = animes.length > 0 ? animes : null;

    return {slice, pageCount};
}

/**
 * Permet de sauvegarder en base un anime passé en paramètre
 *
 * @param anime L'anime à enregistrer
 * @returns {Promise<*>}
 */
export async function createAnime({anime}) {
    const response = await axios.post('/api/v2/animes', anime);
    return response.data.results;
}
