import React, {useContext, useState} from "react";
import {Box, Button, MenuItem, TextField} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import SaveIcon from '@mui/icons-material/Save';
import dayjs from "dayjs";
import "dayjs/locale/fr";

import * as DateUtils from "@src/utils/date.utils";
import * as AnimeUtils from "@src/utils/animes.utils";
import * as HtmlUtils from "@src/utils/html.utils";
import {InputFileUpload} from "@src/components";
import {ModalProvider} from "@src/providers";
import {AuthContext} from "@src/context";
import {Anime} from "@src/models";

const AddAnime = ({notes, suites, updateList}) => {
    // Permet de savoir si l'utilisateur est connecté
    const { isAuthenticated } = useContext(AuthContext);
    // Récupération des appels pour ouvrir les différents modals
    const { openSuccessModal, openErrorModal } = ModalProvider();

    // Champ du formulaire représentant le nom de l'anime
    const [fieldLibelle, setFieldLibelle] = useState("");
    // Champ du formulaire représentant le nombre de saison de l'anime
    const [fieldSaison, setFieldSaison] = useState(0);
    // Champ du formulaire représentant la note de l'anime
    const [fieldNote, setFieldNote] = useState("");
    // Champ du formulaire représentant la suite de l'anime
    const [fieldSuite, setFieldSuite] = useState("");
    // Champ du formulaire représentant la date de visionnage de l'anime
    const [fieldDate, setFieldDate] = useState(dayjs());
    // Champ du formulaire représentant l'image associée à l'anime
    const [fieldImage, setFieldImage] = useState("");

    // Récéptionne l'envoi du formulaire
    const handleSubmit = (e) => {
        e.preventDefault();

        // Je vérifie que les données envoyées sont valides
        HtmlUtils.isValidAnimeForm({
            form: document.getElementById("anime-form")
        }).then(() => {
            const anime = new Anime();
            anime.setLibelle(fieldLibelle);
            anime.setNbSaison(fieldSaison);
            anime.setNote(JSON.parse(fieldNote));
            anime.setSuite(JSON.parse(fieldSuite));
            anime.setDernierVisionnage(DateUtils.formatDate(fieldDate.toDate()));
            anime.setImage(HtmlUtils.getFileName(fieldImage));

            // Appel à la méthode permettant de sauvegarder en base un anime
            // La méthode retourne l'id nouvellement généré si tous c'est bien passé
            AnimeUtils.createAnime({ anime: anime.transformToDTO() }).then(newId => {
                anime.setId(newId);
                anime.applyPrivateAttribut(anime.getNote(), anime.getSuite());
                updateList({anime});

                openSuccessModal({
                    message: `L'anime ${anime.getLibelle()} a bien été créé !`
                });
            }).catch(error => {
                openErrorModal({
                    message: error.message
                });
            });

            // Vide les champs du formulaire
            setFieldLibelle("");
            setFieldSaison(0);
            setFieldNote("");
            setFieldSuite("");
            setFieldDate(dayjs());
            setFieldImage("");
        }).catch(error => {
            openErrorModal({
                message: error
            });
        });
    }

    // On accède à la page que si on est identifié
    if (!isAuthenticated) {
        return;
    }

    return (
        <section id="ajouter-anime">
            <p>Ajouter un anime</p>
            <form id="anime-form" onSubmit={handleSubmit}>
                <div className="grid-container">
                    <Box id="boxLibelle" className="child">
                        <TextField
                            id="libelle"
                            type="text"
                            name="libelle"
                            variant="outlined"
                            label="Libelle"
                            required={true}
                            value={fieldLibelle}
                            onChange={({ target }) => {
                                setFieldLibelle(target.value);
                            }}
                        />
                    </Box>
                    <Box id="boxNbSaison" className="child">
                        <TextField
                            type="number"
                            id="nb_saison"
                            name="nb_saison"
                            variant="outlined"
                            label="Nombre de saison"
                            required={true}
                            value={fieldSaison}
                            onChange={({ target }) => {
                                setFieldSaison(+target.value);
                            }}
                        />
                    </Box>
                    <Box id="boxNote" className="child">
                        {notes &&
                            <TextField
                                select
                                id="note"
                                name="note"
                                className="full-width"
                                label="Avis"
                                variant="outlined"
                                required={true}
                                value={fieldNote}
                                onChange={({ target }) => {
                                    setFieldNote(target.value);
                                }}
                            >
                                {notes.map((note, index) => (
                                    <MenuItem key={index} value={JSON.stringify(note)}>
                                        {note.getLibelle()}
                                    </MenuItem>
                                ))}
                            </TextField>
                        }
                    </Box>
                    <Box id="boxSuite" className="child">
                        {suites &&
                            <TextField
                                select
                                id="suite"
                                name="suite"
                                className="full-width"
                                label="Suite"
                                variant="outlined"
                                required={true}
                                value={fieldSuite}
                                onChange={({ target }) => {
                                    setFieldSuite(target.value);
                                }}
                            >
                                {suites.map((suite, index) => (
                                    <MenuItem key={index} value={JSON.stringify(suite)}>
                                        {suite.getLibelle()}
                                    </MenuItem>
                                ))}
                            </TextField>
                        }
                    </Box>
                    <Box id="boxDateVisionnage" className="child">
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DatePicker
                                label="Date de visionnage"
                                format="YYYY-MM-DD"
                                required={true}
                                value={fieldDate}
                                onChange={setFieldDate}
                                slotProps={{
                                    textField: {
                                        id: "date_visionnage",
                                        name: "date_visionnage",
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box id="boxImage" className="child">
                        <InputFileUpload
                            name="image"
                            className="upload-button full-height"
                            value={fieldImage}
                            onFileChange={(file) => {
                                setFieldImage(file);
                            }}
                        />
                    </Box>
                </div>
                <Button onClick={handleSubmit} className="submit-button" variant="contained" startIcon={<SaveIcon />}>
                    Créer
                </Button>
            </form>
        </section>
    )
};

export default AddAnime;