import React from "react";
import {useSelector} from "react-redux";

import * as DateUtils from "@src/utils/date.utils";
import {selectSort} from "@src/store/filter";

const AboutAnime = ({anime}) => {
    // Text ajouté à la fin de la chaine de charactère "visionné le [date]" d'un anime
    // Exemple pour un vitre sur les suites :
    // - visionné le [date] - Suite prévue
    let detailsFilter = " - ";
    // Letri en cours
    const sort = useSelector(selectSort);

    switch (sort) {
        case 'nb_saison:desc':
            if (anime.getNbSaison() === 0) {
                detailsFilter += "Film";
            } else {
                detailsFilter += anime.getNbSaison();
                detailsFilter += anime.getNbSaison() > 1 ? " saisons" : " saison";
            }
            break;
        case 'note_id:desc':
            if (anime.getNote().getId() !== 7) {
                detailsFilter += `${anime.getNote().getLibelle()} tier`;
            } else {
                detailsFilter += anime.getNote().getLibelle();
            }
            break;
        case 'suite_id:asc':
            if (anime.getSuite().getId() === 1) {
                detailsFilter += "Suite prévue";
            } else {
                if (anime.getSuite().getId() === 2) {
                    detailsFilter += "Suite non-prévue";
                } else {
                    detailsFilter += "Suite indéterminée";
                }
            }
            break;
        default:
            detailsFilter = "";
    }

    return (
        <div className="about-anime">
            <h4>{anime.getLibelle()}</h4>
            <span>visionné le {DateUtils.formatLitteralDate(anime.getDernierVisionnage()) + detailsFilter}</span>
        </div>
    );
};

export default AboutAnime;