import {useState} from "react";
import {useNavigate} from "react-router-dom";

import {LoadingScreen, Navigation, Pagination} from "@src/components"

import {handleMangaClick} from "./handlers/handleMangaClick";
import {useGetMangas} from "./hooks/useGetMangas";

const AllManga = () => {
    // Constante permettant de naviguer entre plusieurs pages
    const navigate = useNavigate();
    // La page courante
    const [currentPage, setCurrentPage] = useState(1);

    // La liste des mangas et le nombre de page associé
    const {mangas, pageCount} = useGetMangas(new URLSearchParams([
        ['page', currentPage],
        ['per_page', 8]
    ]))

    // Mise à jour de la page sur laquelle on se trouve
    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    // Permet de détecter un click en dehors d'un container pour potentiellement lui rendre sa taille d'origine
    window.addEventListener("click", (event) => {
        if (event.target.id === "container-mangas") {
            handleMangaClick(-1);
        }
    });

    document.title = "Mangas - AniMa";

    return (
        <>
            <Navigation/>
            {!mangas ? (
                <LoadingScreen increase={true}/>
            ) : (
                <>
                    <div id="container-mangas">
                        {mangas.map((manga, index) => (
                            <div className="block-manga"
                                 key={index}
                                 id={`block-${index}`}
                                 onClick={() => handleMangaClick(index)}>
                                <img src={`/img/manga/${manga.id}/${manga.cover}`} alt="manga cover" loading="lazy"/>
                                <div className="content">
                                    <h1>{manga.libelle}</h1>
                                    <h4>{manga.scenariste}</h4>
                                    <p>{manga.synopsis}</p>
                                    <div className="tiny-content hide">
                                        <span>{manga.note.libelle ? manga.note.libelle : "∅"}</span>
                                        <button type="button" onClick={() => navigate(`/mangas/${manga.id}`)}>Visiter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Pagination
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        current={currentPage}
                    />
                </>
            )}
        </>
    )
}

export default AllManga;