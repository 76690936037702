const {useEffect} = require("react");

export const useBodyDarker = () => {
    useEffect(() => {
        document.body.classList.add('bg-darker');
        document.querySelector("#root").classList.add('bg-darker');
        return () => {
            document.body.classList.remove('bg-darker');
            document.querySelector("#root").classList.remove('bg-darker');
        }
    }, []);
};