import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";

import {LoadingScreen, Navigation} from "@src/components";
import {useBodyDarker} from "@src/hooks";

import {SplitContent} from "./components";
import {useGetManga, useGetTomes} from "./hooks";

const DetailsManga = () => {
    // L'id du manga passé en paramètre
    const {id} = useParams();
    // Constante permettant de naviguer entre plusieurs pages
    const navigate = useNavigate();
    // Le manga de la page courante
    const {manga} = useGetManga(id);
    // Les tomes du manga ainsi que le tome courant
    const {tomes, currentTome} = useGetTomes(id);

    // Met à jour la page avec les informations du tome courant
    let handleTomeClick = (id) => {
        const tome = tomes.find(tome => tome.id === id);
        navigate(`/mangas/${manga.id}?tome=${tome.numero}`);
    };

    // Déselectionne le tome courant replace l'utilisateur sur l'URL par défaut
    let handleClearSelection = () => {
        navigate(`/mangas/${manga.id}`);
    };

    // Permet d'ajouter la classe "bg-darker" au body et à root lors de la visite de cette page
    useBodyDarker();

    // Mise à jour du titre de la page en fonction du tome
    if (manga) {
        if (currentTome) {
            document.title = `${manga.libelle} Vol.${currentTome.numero} - AniMa`;
        } else {
            document.title = `${manga.libelle} - AniMa`;
        }
    }

    return (
        <>
            <Navigation/>
            {!manga ? (
                <LoadingScreen increase={true}/>
            ) : (
                <section className="bg-text">
                    <div className="bg-side-image" style={{backgroundImage: `url(${manga.image})`}}>
                    </div>
                    <section id="container-manga" className="absolute-top-full-width">
                        <SplitContent manga={manga} currentTome={currentTome}/>
                        <hr/>
                        <section id="tomes">
                            <div>
                                <h1>Liste des volumes :</h1>
                                {currentTome &&
                                    <span onClick={handleClearSelection}>
                                    <FontAwesomeIcon icon={faCircleXmark}/> Effacer la sélection
                                </span>}
                            </div>
                            {!tomes ? (
                                <LoadingScreen/>
                            ) : (
                                <>
                                    {tomes.map((tome, index) => (
                                        <img key={index}
                                             src={`/img/manga/${manga.id}/${tome.cover}`}
                                             alt="cover du tome"
                                             onClick={() => handleTomeClick(tome.id)}
                                             className={(currentTome && tome.id === currentTome.id) ? "active" : ""}
                                        />
                                    ))}
                                </>
                            )}
                        </section>
                    </section>
                </section>
            )}
        </>
    )
}

export default DetailsManga;