import React from "react";

// Affichage du contenu caché (Voir .aside-content)
const handleSidePanel = () => {
    const body = document.querySelector("body");
    const blackScreen = document.querySelector(".black-screen");
    const asideContent = document.querySelector(".aside-content");

    body.classList.add("no-scroll");
    blackScreen.style.visibility = "visible";
    asideContent.style.right = 0;

    blackScreen.addEventListener('click', (event) => {
        if (!asideContent.contains(event.target)) {
            body.classList.remove("no-scroll");
            blackScreen.style.visibility = "hidden";
            asideContent.style.right = "";
        }
    });
};

const ToggleSidePanel = () => (
    <div className="toggle-sidepanel" onClick={handleSidePanel}>
        <span>Utilitaires</span>
    </div>
)

export default ToggleSidePanel;