import React from "react";

import * as DateUtils from "@src/utils/date.utils";

const TomeDetails = ({currentTome}) => (
    <div className="bloc-title">
        <h3>{`Publication Vol.${currentTome.numero} :`}</h3>
        <span>{DateUtils.formatLitteralDate(currentTome.date_parution)}</span>
    </div>
);

export default TomeDetails;