import React from 'react';
import ResponsivePagination from 'react-responsive-pagination';

let Pagination = ({current, pageCount, handlePageClick}) => (
    <ResponsivePagination
        current={current}
        total={pageCount}
        onPageChange={page => handlePageClick(page)}
        activeItemClassName={'active'}
        pageItemClassName={'page-item'}
        pageLinkClassName={'page-link'}
        nextClassName={'page-item next'}
        previousClassName={'page-item previous'}
        className={'pagination'}
        previousLabel={'Previous'}
        nextLabel={'Next'}
    />
);


export default Pagination;