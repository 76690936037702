// Modification, à l'entrée de la souris, de la taille et de l'emplacement du contenu
export const handleMouseEnter = (left) => {
    const container = document.querySelector('.container-fluid');

    if (left) {
        container.classList.add('hover-left');
        container.classList.add('hover-top');
    } else {
        container.classList.add('hover-right');
        container.classList.add('hover-bottom');
    }
};