import {createSlice} from "@reduxjs/toolkit";

const animesSlice = createSlice({
    name: 'animes',
    initialState: [],

    reducers: {
        addAnime: (state, action) => {
            state.push(action.payload);
        },
        removeAnime: (state, action) => {
            const index = state.findIndex(anime => anime.id === action.payload);
            if (index !== -1) {
                state.splice(index, 1);
            }
        },
    },
});

// Exports actions
export const {
    addAnime,
    removeAnime,
} = animesSlice.actions;

// Exports reducer
export default animesSlice.reducer;