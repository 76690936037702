import {useEffect, useState} from "react";
import axios from "axios";

export const useGetData = (url, params) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const paramsStringify = params.toString();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(url, {params});
                setResponse(result.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        void fetchData();
        // eslint-disable-next-line
    }, [url, paramsStringify]);

    return {response, error, loading};
};