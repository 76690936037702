import React from "react";

import TomeDetails from "../TomeDetails/TomeDetails";
import MangaDetails from "../MangaDetails/MangaDetails";
import Adaptation from "../Adaptation/Adaptation";

const SplitContent = ({manga, currentTome}) => (
    <section className="split-content">
        <div className="left">
            <div className="content-left">
                <div className="display-img"
                     style={{backgroundImage: currentTome ? `url(/img/manga/${manga.id}/${currentTome.cover})` : `url(/img/manga/${manga.id}/${manga.cover})`}}
                />
                <div className="genres">
                    {(manga.genres).map((genre, index) => (
                        <div key={index}>
                            <span>{genre.libelle}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <div className="right">
            <h1>{currentTome ? manga.libelle + " : Vol." + currentTome.numero : manga.libelle}</h1>
            <p className="synopsis" dangerouslySetInnerHTML={{__html: currentTome ? currentTome.synopsis : manga.synopsis}}></p>
            <div className="content-right">
                {currentTome
                    ? <TomeDetails currentTome={currentTome}/>
                    : <MangaDetails manga={manga}/>
                }
                <div className="bloc-title">
                    <h3>Scénariste :</h3>
                    <span>{manga.scenariste}</span>
                </div>
                <div className="bloc-title">
                    <h3>Illustrateur :</h3>
                    <span>{manga.illustrateur}</span>
                </div>
                <div className="bloc-title">
                    <h3>Type</h3>
                    <span>{manga.type.libelle}</span>
                </div>
                <div className="bloc-title">
                    <h3>Edition</h3>
                    <span>{manga.edition.libelle}</span>
                </div>
                <div className="bloc-title">
                    <h3>Adaptation :</h3>
                    <Adaptation manga={manga}/>
                </div>
            </div>
        </div>
    </section>
)

export default SplitContent;