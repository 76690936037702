const months = new Map();

months.set(1, 'janvier');
months.set(2, 'février');
months.set(3, 'mars');
months.set(4, 'avril');
months.set(5, 'mai');
months.set(6, 'juin');
months.set(7, 'juillet');
months.set(8, 'août');
months.set(9, 'septembre');
months.set(10, 'octobre');
months.set(11, 'novembre');
months.set(12, 'décembre');

// Transforme une date dans un format 14 novembre 2002
export function formatLitteralDate(date) {
    const d = new Date(date);
    const day = d.getDate();
    const year = d.getFullYear();
    // getMonth() return an integer between 0 and 11.
    const month = months.get(d.getMonth() + 1);

    return day + " " + month + " " + year;
}

// Transforme une date dans un format 2002-11-14
export function formatDate(date) {
    const currentDay= String(date.getDate()).padStart(2, '0');
    const currentMonth = String(date.getMonth()+1).padStart(2,"0");
    const currentYear = date.getFullYear();

    return `${currentYear}-${currentMonth}-${currentDay}`;
}