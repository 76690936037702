import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as FilterStore from "@src/store/filter";

let Preferences = ({notes, suites}) => {
    // Le filtre sur les notes et suites
    const filterNote = useSelector(FilterStore.selectFilterNote);
    const filterSuite = useSelector(FilterStore.selectFilterSuite);

    // Permet de faire appel aux actions du reducer
    const dispatch = useDispatch();

    // Mise à jour du tri
    const handleChangeSort = (event) => {
        dispatch(FilterStore.changeSort({value: event.target.value}));
    };

    // Mise à jour du filtre sur les notes.
    // On se replace sur la première page
    const handleChangeFilterNote = (event) => {
        dispatch(FilterStore.changeFilterNote({value: event.target.value}));
    };

    // Mise à jour du filtre sur les suites.
    // On se replace sur la première page
    const handleChangeFilterSuite = (event) => {
        dispatch(FilterStore.changeFilterSuite({value: event.target.value}))
    };

    return (
        <div id="preferences">
            <p>Préférences</p>
            <div className="grid-container">
                <div id="container-tri">
                    <p>Trier</p>
                    <div onChange={handleChangeSort}>
                        <div className="inner-block">
                            <input type="radio" id="tri-libelle" name="trier" value="libelle:asc"/>
                            <label htmlFor="tri-libelle">Par titre</label>
                        </div>
                        <div className="inner-block">
                            <input type="radio" id="tri-saison" name="trier" value="nb_saison:desc"/>
                            <label htmlFor="tri-saison">Par nombre de saison</label>
                        </div>
                        <div className="inner-block">
                            <input type="radio" id="tri-avis" name="trier" value="note_id:desc"/>
                            <label htmlFor="tri-avis">Par avis</label>
                        </div>
                        <div className="inner-block">
                            <input type="radio" id="tri-suite" name="trier" value="suite_id:asc"/>
                            <label htmlFor="tri-suite">Par suite</label>
                        </div>
                        <div className="inner-block">
                            <input type="radio" id="tri-date" name="trier" value="dernier_visionnage:desc"
                                   defaultChecked={true}/>
                            <label htmlFor="tri-date">Par date de visionnage</label>
                        </div>
                    </div>
                </div>
                <div id="container-filtre">
                    <p>Filtrer</p>
                    <div>
                        {notes && (
                            <select value={filterNote} onChange={handleChangeFilterNote}>
                                <option value="0" hidden={true}>Par avis</option>
                                {notes.map((note, index) => (
                                    <option key={index} value={note.getId()}>
                                        {note.getLibelle()}
                                    </option>
                                ))};
                            </select>
                        )}
                        {suites && (
                            <select value={filterSuite} onChange={handleChangeFilterSuite}>
                                <option value="0" hidden={true}>Par suite</option>
                                {suites.map((suite, index) => (
                                    <option key={index} value={suite.getId()}>
                                        {suite.getLibelle()}
                                    </option>
                                ))};
                            </select>
                        )}
                        {(filterSuite > 0 || filterNote > 0) &&
                            <button type="button" onClick={() => dispatch(FilterStore.removeFilter())}>Supprimer le filtre</button>}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Preferences;