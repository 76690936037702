import {useState} from "react";
import {nl2br} from "react-js-nl2br";
import {useNavigate, useParams} from "react-router-dom";

import {LoadingScreen, Navigation, Pagination} from "@src/components";
import {useGetAnimesGenre, useGetGenre} from "./hooks";

const DetailsGenre = () => {
    // La page courrante
    const [currentPage, setCurrentPage] = useState(1);
    // L'id du genre passé en paramètre
    const {id} = useParams();
    // Constante permettant de naviguer entre plusieurs pages
    const navigate = useNavigate();

    // Le genre de la page courante
    const {genre} = useGetGenre(id);
    // Les animes appartenant au genre, le nombre de page et le status de la requête
    const {animes, pageCount, loading} = useGetAnimesGenre(id, new URLSearchParams([
        ['per_page', 32],
        ['page', currentPage]
    ]));

    // Mise à jour de la page sur laquelle on se trouve
    let handlePageClick = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <Navigation/>
            {!genre ? (
                <LoadingScreen increase={true}/>
            ) : (
                <section id="container-genre">
                    <div id="details-genre">
                        <h1>{genre.libelle}</h1>
                        <p dangerouslySetInnerHTML={{__html: genre.description}}></p>
                    </div>
                    {!animes ? (
                        loading ? (
                            <LoadingScreen increase={true}/>
                        ) : (
                            <div className="no-result">
                                <h1>Aucun anime ne porte le genre <b>{genre.libelle}</b></h1>
                                <span>Essayer de rechercher ce genre parmi les mangas</span>
                            </div>
                        )
                    ) : (
                        <div className="grid-container">
                            {animes.map((anime, index) => (
                                <div key={index} className="grid-item" onClick={() => navigate(`/animes/${anime.id}`)}>
                                    <img src={`/img/anime/${anime.id}/${anime.image}`} alt="Preview de l'anime"
                                         loading="lazy"/>
                                    <h3>{anime.libelle}</h3>
                                    <p>{nl2br(anime.synopsis)}</p>
                                </div>
                            ))}
                        </div>
                    )}
                    <Pagination
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        current={currentPage}
                    />
                </section>
            )}
        </>
    )
}

export default DetailsGenre;