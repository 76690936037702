import React, {forwardRef, useState} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-regular-svg-icons";

const ShowAndHidePassword = forwardRef((props, ref) => {
    const [isPwdType, setIsPwdType] = useState(true);

    return (
        <div className="password-input">
            <input type={isPwdType ? "password" : "text"} name="password" id="password" ref={ref} />
            <FontAwesomeIcon icon={isPwdType ? faEyeSlash : faEye} onClick={() => setIsPwdType(!isPwdType)}/>
        </div>
    );
});

export default ShowAndHidePassword;