import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    filterNote: 0,
    filterSuite: 0,
    sort: "dernier_visionnage:desc",
};

const filterSlice = createSlice({
    name: 'filtering',
    initialState,

    reducers: {
        changeFilterNote: (state, action) => {
            state.filterNote = action.payload.value;
        },
        changeFilterSuite: (state, action) => {
            state.filterSuite = action.payload.value;
        },
        changeSort: (state, action) => {
            state.sort = action.payload.value;
        },
        removeFilter: (state) => {
            state.filterNote = initialState.filterNote;
            state.filterSuite = initialState.filterSuite;
        },
    },
});

// Exports actions
export const {
    changeFilterNote,
    changeFilterSuite,
    changeSort,
    removeFilter,
} = filterSlice.actions;

// Exports reducer
export default filterSlice.reducer;