import {useEffect, useState} from "react";

import {useGetData} from "@src/hooks";

export const useGetAnime = (id) => {
    // L'anime récupéré
    const [anime, setAnime] = useState(null);
    // La réponse de la requête API
    const {response} = useGetData(`/api/v2/animes/${id}`, {});

    useEffect(() => {
        if (response) {
            const data = response.results;

            document.title = `${data.libelle} - AniMa`;
            setAnime(data);
        }
    }, [response]);

    return {anime};
}