import {useContext} from "react";

import {ModalContext} from "@src/context";

const ModalProvider = () => {
    // Permet de gérer l'état de la modal
    const { setShowModal, setMessageModal, setSuccessModal } = useContext(ModalContext);

    /**
     * Ouverture d'une modal de succès
     *
     * @param message Message à afficher
     */
    function openSuccessModal({message}) {
        setMessageModal(message);
        setSuccessModal(true);
        setShowModal(true);
    }

    /**
     * Ouverture d'une modale d'erreur
     *
     * @param message Message à afficher
     */
    function openErrorModal({message}) {
        setMessageModal(message);
        setSuccessModal(false);
        setShowModal(true);
    }

    return {
        openSuccessModal,
        openErrorModal
    };
};

export default ModalProvider;