import React, {useContext} from 'react';
import {NavLink, useLocation} from 'react-router-dom';

import {Searchbar} from '@src/components';
import {AuthContext} from "@src/context";
import {logout} from "@src/services/AuthService";

const Navigation = () => {
    // Permet de savoir si l'utilisateur est connecté
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    // Hook permettant de récupérer la localisation actuelle
    const location = useLocation();
    // Les chemins qui prennent en compte un système de recherche
    const autorizeSearchPath = [
        "/animes"
    ];
    // Boolean permettant de savoir si on inclut la searchbar dans la page courante
    const includeSearchbar = autorizeSearchPath.includes(location.pathname);

    // Permet de déconnecter l'utilisateur existant
    const handleLogout = () => {
        logout();
        setIsAuthenticated(false);
    };

    return (
        <div className="navigation">
            <ul>
                <li>
                    <NavLink
                        to="/"
                        className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        Accueil
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/animes"
                        className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        Animes
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/mangas"
                        className={(nav) => (nav.isActive) ? "nav-active" : ""}>
                        Mangas
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/genres"
                        className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        Genres
                    </NavLink>
                </li>
                {includeSearchbar ? <Searchbar/> : ""}
                {!isAuthenticated ? (
                    <li id="login-link" className="align-left">
                        <NavLink
                            to="/login"
                            className={(nav) => (nav.isActive ? "nav-active" : "")}>
                            Connexion
                        </NavLink>
                    </li>
                ) : (
                    <li id="logout-link" className="align-left">
                        <button onClick={handleLogout}>Déconnexion</button>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default Navigation;