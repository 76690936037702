import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useContext} from "react";
import {ModalContext} from "@src/context";

let title = "Erreur";

const ResponseModal = () => {
    const {showModal, setShowModal, messageModal, successModal} = useContext(ModalContext);

    if (showModal) {
        if (successModal) {
            title = "Confirmation";
        }

        return (
            <section id="zone-modal">
                <div className={successModal === true ? "success-modal" : "error-modal"}>
                    <div id="header">
                        <h3>{title}</h3>
                        <span onClick={() => setShowModal(false)}>
                            <FontAwesomeIcon icon={faXmark} size={"xl"}/>
                        </span>
                    </div>
                    <div id="body">
                        <p dangerouslySetInnerHTML={{__html: messageModal}}></p>
                        <hr/>
                        <button onClick={() => setShowModal(false)}>Fermer</button>
                    </div>
                </div>
            </section>
        );
    }
};

export default ResponseModal;