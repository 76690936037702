import {useEffect, useState} from "react";

import {useGetData} from "@src/hooks";
import {Note} from "@src/models";

export const useGetNotes = () => {
    // La liste des données récupérées
    const [notes, setNotes] = useState(null);
    // La réponse de la requête API
    const {response} = useGetData("/api/v2/notes", {});

    useEffect(() => {
        if (response) {
            const data = response.results.map(note => new Note(note.id, note.libelle));
            setNotes(data);
        }
    }, [response]);

    return {notes};
}