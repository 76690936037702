// Modification, à la sortie de la souris, de la taille et de l'emplacement du contenu
export const handleMouseLeave = (left) => {
    const container = document.querySelector('.container-fluid');

    if (left) {
        container.classList.remove('hover-left');
        container.classList.remove('hover-top');
    } else {
        container.classList.remove('hover-right');
        container.classList.remove('hover-bottom');
    }
};