import {useEffect, useState} from "react";

import {useGetData} from "@src/hooks";

export const useGetAnimesGenre = (id, params) => {
    // les animes du genre
    const [animes, setAnimes] = useState(null);
    // Le nombre de page
    const [pageCount, setPageCount] = useState(0);
    // La réponse de la requête API
    const {response, loading} = useGetData(`/api/v2/genres/${id}/animes`, params);

    useEffect(() => {
        if (response) {
            setAnimes(response.results);
            setPageCount((Math.ceil(response.meta.total_results / response.meta.results_per_page)));
        }
    }, [response]);

    return {animes, pageCount, loading};
}