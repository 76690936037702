import React, {useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import Home from "./pages/Home";
import {Login} from "./components";
import {AllAnime, DetailsAnime} from "./pages/Anime"
import {AllManga, DetailsManga} from "./pages/Manga"
import {AllGenre, DetailsGenre} from "./pages/Genre";
import {hasAuthenticated} from "./services/AuthService";
import {AuthContext} from "./context";

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(hasAuthenticated());

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<Home/>}/>
                    <Route path="/accueil" element={<Home/>}/>
                    <Route exact path="/animes" element={<AllAnime/>}/>
                    <Route exact path="/animes/:id" element={<DetailsAnime/>}/>
                    <Route path="/genres" element={<AllGenre/>}/>
                    <Route path="/genres/:id" element={<DetailsGenre/>}/>
                    <Route path="/mangas" element={<AllManga/>}/>
                    <Route path="/mangas/:id" element={<DetailsManga/>}/>
                    <Route path="/login" element={<Login />} />
                </Routes>
            </BrowserRouter>
        </AuthContext.Provider>
    );
};

export default App;
