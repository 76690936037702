import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {useGetData} from "@src/hooks";

export const useGetTomes = (id) => {
    // Les tomes du manga
    const [tomes, setTomes] = useState(null);
    // Le tome courant
    const [currentTome, setCurrentTome] = useState(null);
    // Les paramètres présents dans l'URL de la page
    const [queryParameters] = useSearchParams();
    // La réponse de la requête API
    const {response} = useGetData(`/api/v2/mangas/${id}/tomes`, {});

    useEffect(() => {
        if (response) {
            const data = response.results;

            setTomes(data);

            if (queryParameters.get('tome')) {
                const tome = data.find(tome => tome.numero === +queryParameters.get('tome'));
                setCurrentTome(tome);
            } else {
                setCurrentTome(null);
            }
        }
    }, [response, queryParameters]);

    return {tomes, currentTome};
}