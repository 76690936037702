import {useEffect, useState} from "react";

import {useGetData} from "@src/hooks";

export const useGetGenre = (id) => {
    // Le genre récupéré
    const [genre, setGenre] = useState(null);
    // La réponse de la requête API
    const {response} = useGetData(`/api/v2/genres/${id}`, {});

    useEffect(() => {
        if (response) {
            const data = response.results;

            document.title = `${data.libelle} - AniMa`;
            setGenre(data);
        }
    }, [response]);

    return {genre};
}