import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

import {Pagination, ToggleSidePanel} from "@src/components";
import * as AnimeUtils from "@src/utils/animes.utils";
import {selectFilterNote, selectFilterSuite, selectSort} from "@src/store/filter";

import {AboutAnime} from "../../components";

const AnimesList = ({animes}) => {
    // Constante permettant de naviguer entre plusieurs pages
    const navigate = useNavigate();
    // Le nombre de page
    const [pageCount, setPageCount] = useState(1);
    // La page courante
    const [currentPage, setCurrentPage] = useState(1);
    // La liste d'animes à afficher
    const [displayList, setDisplayList] = useState([]);

    // Les filtres sur les notes et suite ainsi que le tri des animes
    const filterNote = useSelector(selectFilterNote)
    const filterSuite = useSelector(selectFilterSuite)
    const sort = useSelector(selectSort);

    useEffect(() => {
        if (animes) {
            const {slice, pageCount} = AnimeUtils.getDisplayList(animes, currentPage, 20, {filterNote, filterSuite, sort});

            setDisplayList(slice);
            setPageCount(pageCount)
        }
    }, [animes, currentPage, filterNote, filterSuite, sort]);

    // Mise à jour de la page courante
    const handlePageClick = (page) => {
        setCurrentPage(page);
    }

    if (!animes) {
        return (
            <div id="animes-list">
                <div id="listOfAnimes">
                    <div className="no-result">
                        <h1>aucun résultat trouvé !</h1>
                        <span>il se peut qu'aucun anime ne correspond au filtre</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div id="animes-list">
            <ToggleSidePanel/>
            <div id="listOfAnimes">
                {displayList ? (
                    displayList.map((anime, index) => (
                        <div id="content-anime" onClick={() => navigate(`/animes/${anime.getId()}`)}
                             className="flex-box" key={index}>
                            <div className="img-preview">
                                <img src={`img/anime/${anime.getId()}/${anime.getImage()}`}
                                     alt="anime preview"
                                     loading="lazy"/>
                            </div>
                            <AboutAnime anime={anime}/>
                        </div>
                    ))
                ) : (
                    <div id="animes-list">
                        <div id="listOfAnimes">
                            <div className="no-result">
                                <h1>aucun résultat trouvé !</h1>
                                <span>il se peut qu'aucun anime ne correspond au filtre</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Pagination
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                current={currentPage}
            />
        </div>
    )
}

export default AnimesList;