import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {useGetData} from "@src/hooks";

export const useGetGenres = (params) => {
    // La liste des genres
    const [genres, setGenres] = useState(null);
    // Le nombre de page
    const [pageCount, setPageCount] = useState(null);
    // Les paramètres présents dans l'URL de la page
    const [queryParameters] = useSearchParams();

    // Ajout aux options de la requête le contenu du paramètre de l'URL
    let alphabetFilter = null;
    if (queryParameters.get('startWith')) {
        alphabetFilter = queryParameters.get('startWith');

        params.append('startWith', alphabetFilter);
    }

    // La réponse de la requête API
    const {response, loading} = useGetData("/api/v2/genres", params);

    useEffect(() => {
        if (response) {
            setGenres(response.results);
            setPageCount((Math.ceil(response.meta.total_results / response.meta.results_per_page)));
        }
    }, [response]);

    return {genres, pageCount, loading, alphabetFilter};
}