import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";

import {configureAppStore} from "@src/store/configureAppStore";

import App from './App';
import './style/index.scss';

const storePromise = configureAppStore();
const root = ReactDOM.createRoot(document.getElementById('root'));

storePromise.then(store => {
    root.render(
        <Provider store={store}>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </Provider>
    );
});
