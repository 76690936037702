import React from "react";

import * as HtmlUtils from "@src/utils/html.utils";

// Alphabet français
const alphabet = HtmlUtils.generateAlphabet();

const SortGenres = ({alphabetFilter, handleSortGenres}) => (
    <div id="sort-genres">
        <div>
            <span
                className={!alphabetFilter ? "active" : ""}
                onClick={() => handleSortGenres()}
            >Tous</span>
            {alphabet.map((letter, index) => (
                <span
                    key={index}
                    onClick={() => handleSortGenres(letter)}
                    className={alphabetFilter === letter ? "active" : ""}
                >{letter}</span>
            ))}
        </div>
    </div>
)

export default SortGenres;