import React, {useState} from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckIcon from '@mui/icons-material/Check';
import {Button} from "@mui/material";

const InputFileUpload = ({name, className, value, onFileChange}) => {
    // Label affiché sur le bouton
    const [fileName, setFileName] = useState("");

    // Appel de la fonction lorsqu'un fichier est upload via le bouton
    const handleFileChange = (e) => {
        e.preventDefault();

        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            setFileName(file.name);
            onFileChange(file);
        }
    }

    return (
        <Button
            id="upload-button"
            className={`${className} ${value && 'isUpload'}`}
            component="label"
            variant="outlined"
            startIcon={value ? <CheckIcon /> : <CloudUploadIcon />}
        >
            <div className="truncate">{value ? fileName : "Télécharger une image"}</div>
            <input
                name={name}
                accept="image/*"
                hidden={true}
                type="file"
                onChange={handleFileChange}
            />
        </Button>
    )
}

export default InputFileUpload;