import React from "react";
import {useNavigate} from "react-router-dom";

const Adaptation = ({manga}) => {
    // Constante permettant de naviguer entre plusieurs pages
    const navigate = useNavigate();

    if (manga.anime.id) {
        return (
            <span id="adaptation" onClick={() => navigate(`/animes/${manga.anime.id}`)}>
                {manga.anime.libelle}
            </span>
        );
    } else {
        return (<span>Aucune adaptation en anime pour le moment</span>);
    }
};

export default Adaptation;