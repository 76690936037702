import React from "react";
import {useNavigate} from "react-router-dom";

const GenresList = ({genres}) => {
    // Constante permettant de naviguer entre plusieurs pages
    const navigate = useNavigate();

    return (
        <div className="container grid-container">
            {genres.map((genre, index) => (
                <div className="grid-item" key={index} onClick={() => navigate(`/genres/${genre.id}`)}>
                    <h3>{genre.libelle}</h3>
                    <div id="genre-summary">
                        <p dangerouslySetInnerHTML={{__html: genre.description}}></p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default GenresList;