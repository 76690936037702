import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const LoadingScreen = ({increase = false}) => (
    <section id="loading-screen" className={`${increase ? "full-screen-icon" : ""}`}>
        <FontAwesomeIcon icon={faSpinner} pulse={true}/>
        <span> Chargement ...</span>
    </section>
)


export default LoadingScreen;