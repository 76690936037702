import axios from "axios";
import {configureStore} from "@reduxjs/toolkit";

import {Anime, Note, Suite} from "@src/models";
import filterSlice from "@src/store/filter/filterSlice";
import animesSlice from "@src/store/animes/animesSlice";

const fetchInitialAnimes = async () => {
    let animes = [];

    try {
        const response = await axios.get('/api/v2/animes?per_page=999');
        const result = response.data.results;

        for (let i = 0; i < result.length; i++) {
            animes.push(new Anime(
                result[i].id,
                result[i].libelle,
                result[i].synopsis,
                result[i].image,
                result[i].carte_image,
                result[i].nb_saison,
                new Note(result[i].note.id, result[i].note.libelle),
                new Suite(result[i].suite.id, result[i].suite.libelle),
                result[i].dernier_visionnage
            ));
        }
        return animes;
    } catch (error) {
        console.log(error);
        return animes;
    }
}

export const configureAppStore = async () => {
    const initialAnimes = await fetchInitialAnimes();

    return configureStore({
        reducer: {
            animes: animesSlice,
            filtering: filterSlice,
        },
        preloadedState: {
            animes: initialAnimes
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false
        }),
    })
};